import { ComplianceStatus } from '../types';
import { match } from 'ts-pattern';
import { ErrorAlertBox, InactiveBox, SuccessAlertBox, WarningAlertBox } from './AlertBoxes.styled';
import { Typography } from '@mui/material';

const ComplianceAlertBox: React.FC<{ compliance_status: ComplianceStatus }> = ({ compliance_status }) => {
  return match({ compliance_status })
    .with({ compliance_status: ComplianceStatus.NotStarted }, () => (
      <ErrorAlertBox>
        <Typography sx={{lineHeight: '1.1'}} variant="alert">action required</Typography>
      </ErrorAlertBox>
    ))
    .with({ compliance_status: ComplianceStatus.InProgress }, () => (
      <WarningAlertBox>
        <Typography sx={{lineHeight: '1.1'}}  variant="alert">attention needed</Typography>
      </WarningAlertBox>
    ))
    .with({ compliance_status: ComplianceStatus.Complete }, () => (
      <SuccessAlertBox>
        <Typography sx={{lineHeight: '1.1'}}  variant="alert">up to date</Typography>
      </SuccessAlertBox>
    ))
    .with({ compliance_status: ComplianceStatus.NotApplicable }, () => (
      <InactiveBox>
        <Typography sx={{lineHeight: '1.1'}} variant="alert">service inactive</Typography>
      </InactiveBox>
    ))
    .otherwise(() => null);
};

export default ComplianceAlertBox;
