import React from 'react';
import TrainingDashboard from '../../../views/practice/training/Training';

const trainingRoutes = [
  {
    path: '/practice/training',
    element: <TrainingDashboard />
  }
];

export default trainingRoutes;
