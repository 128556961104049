import { Box, styled } from '@mui/material';

export const AlertBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '10px'
}));

export const InactiveBox = styled(AlertBox)(({ theme }) => ({
  background: theme.palette.inactive.main,
  color: theme.palette.common.white,
}));

export const ErrorAlertBox = styled(AlertBox)(({ theme }) => ({
  backgroundColor: theme.palette.danger.main,
  color: theme.palette.common.white
}));

export const WarningAlertBox = styled(AlertBox)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.colorfef6e4.main
}));

export const SuccessAlertBox = styled(AlertBox)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.colorc2f3ce.main
}));
