import React, { useEffect, useState } from 'react';
import { Link as RouterLink, Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { Box, Button, ClickAwayListener, Collapse, Grow, Link, MenuItem, MenuList, Paper, Popper, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import constants from '../../../constants';
import { deviceIcon, infectionControlIcon, oshaHipaaIcon, medicationIcon, waterlineIcon, airSurfaceDisinfection, sharpsIcon, unusedMeds1, amalgam2 } from '../../../icons/index';
import styles from './PracticeDashboard.module.css';
import { getChildPractice } from '../../../services/customerService';
import { ChildCustomerDetails } from '../../../types';
import { setChildCustomer } from '../../../redux/childCustomer';

// Available menus
enum Menus {
  None = '',
  InfectionControl = 'infection_control_root_menu',
  WasteManagement = 'waste_managemnt_root_menu',
  Tasks = 'tasks_root_menu',
  InfectionControl_Biological = 'infection_control_biological_sub_menu',
}

const buttonStyle = {
  color: '#50545A',
  textTransform: 'none'
};

const PracticeDashboard = () => {
  const { customerNumber } = useParams();
  const dispatch = useDispatch();
  const [childCustomerDetails, setChildCustomerDetails] = useState<ChildCustomerDetails | undefined>();

  const getChildCustomer = async (customerNumber: string) => {
    const childCustomer = await getChildPractice(customerNumber);
    if (childCustomer) {
      setChildCustomerDetails(childCustomer);
      dispatch(setChildCustomer(childCustomer));
    }
  }

  // menu management
  const [ menuOpen, setMenuOpen] = useState(Menus.None);
  const [ subMenuOpen, setSubMenuOpen] = useState(Menus.None);
  const [ menuAnchorRef, setMenuAnchorRef ] = useState<HTMLButtonElement | null>(null);

  // set active root-level menu
  const setActiveMenu = (menu: Menus = Menus.None, target: HTMLButtonElement | null = null) => {
    // if closing just close and return
    if (menu === Menus.None || menu === menuOpen) {
      clearMenus();
      return;
    }

    // remove previous menu
    clearMenus();

    // give popper a moment to remove the previous menu from the screen
    setTimeout(() => {
      // open new menu
      setMenuAnchorRef(target);
      setMenuOpen(menu);
    }, 100);
  }

  // set active sub-menu
  const setActiveSubMenu = (menu: Menus = Menus.None, target: HTMLButtonElement | null = null) => {
    if(subMenuOpen !== menu) {
      // open menu
      setSubMenuOpen(menu);
    } else {
      // clicked same menu again = close.
      setSubMenuOpen(Menus.None);
    }
  }

  // hide/clear all menus
  const clearMenus = () => {
    setSubMenuOpen(Menus.None);
    setMenuOpen(Menus.None);
    setMenuAnchorRef(null);
  }

  useEffect(() => {
    if (customerNumber) {
      getChildCustomer(customerNumber);
    }
  }, [customerNumber]);

  return (
    <div>
      <div className={styles.header}>
        {childCustomerDetails?.customer_number !== undefined && (
          <>
            <div className={styles.name}>{childCustomerDetails?.name}</div>
            <div>
              <div>{childCustomerDetails?.doctor_name} | #{childCustomerDetails?.customer_number}</div>
              <div>{childCustomerDetails?.street} {childCustomerDetails?.state} {childCustomerDetails?.zip}</div>
              <div>{childCustomerDetails?.email} | {childCustomerDetails?.phone}</div>
            </div>
          </>
        )}
      </div>
      <div className={styles.nav}>
        <Link href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/dashboard`}>
          <Button>
            <FontAwesomeIcon icon="house-chimney" className={styles.fa_icon} />
          </Button>
        </Link>
        <Link href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/medications`}>
          <Button sx={buttonStyle}><img className={styles.image_icon} src={medicationIcon} />Medications</Button>
        </Link>
        <Link href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/devices`}>
          <Button sx={buttonStyle}><img className={styles.image_icon} src={deviceIcon} />Devices</Button>
        </Link>
        {/* INFECTION CONTROL  */}
        <Button sx={buttonStyle} onClick={({ currentTarget }) => setActiveMenu(Menus.InfectionControl, currentTarget)}>
          <Stack direction="row" spacing={1} alignItems="center">
            <img className={styles.image_icon} src={infectionControlIcon} />
            <Typography variant="subtitle2" textTransform="capitalize">infection control</Typography>
            <FontAwesomeIcon icon="caret-down" className={styles.fa_icon} />
          </Stack>
        </Button>
        <Popper
          anchorEl={menuAnchorRef}
          open={menuOpen === Menus.InfectionControl}
          placement={'bottom-start'}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={() => clearMenus()}>
                  <MenuList id="infection-control-menu">
                    <MenuItem>
                      <Stack>
                        <Button sx={{ ...buttonStyle, padding: 0 }} onClick={({ currentTarget }) => { setActiveSubMenu(Menus.InfectionControl_Biological, currentTarget) }}>
                          <Stack direction="row" spacing={1} alignItems="center" width="100%" justifyContent="flex-start">
                            <img className={styles.image_icon} src={infectionControlIcon} />
                            <Typography variant="subtitle2" textTransform="capitalize">biological</Typography>
                            <Box flexGrow="1" display="flex" justifyContent="flex-end">
                              <FontAwesomeIcon icon="chevron-right" />
                            </Box>
                          </Stack>
                        </Button>
                        <Collapse in={subMenuOpen === Menus.InfectionControl_Biological}>
                          <Paper sx={{ background: 'transparent', border: 'none', boxShadow: 'none' }}>
                            <MenuList id="biological-sub-menu">
                              <MenuItem>
                                <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/biological`}><Typography variant="subtitle2" textTransform="capitalize">biological monitoring</Typography></Link>
                              </MenuItem>
                              <MenuItem>
                                <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/quarterly-reports`}><Typography variant="subtitle2" textTransform="capitalize">biological quarter report</Typography></Link>
                              </MenuItem>
                              <MenuItem>
                                <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/annual-reports`}><Typography variant="subtitle2" textTransform="capitalize">biological annual report</Typography></Link>
                              </MenuItem>
                            </MenuList>
                          </Paper>
                        </Collapse>
                      </Stack>
                    </MenuItem>
                    <MenuItem>
                      <img className={styles.image_icon} src={waterlineIcon} />
                      <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/waterline-management`}><Typography variant="subtitle2" textTransform="capitalize">waterline management</Typography></Link>
                    </MenuItem>
                    <MenuItem>
                      <img className={styles.image_icon} src={airSurfaceDisinfection} />
                      <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/air-surface-disinfection`}><Typography variant="subtitle2" textTransform="capitalize">air and surface disinfection</Typography></Link>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Link component={RouterLink} to={`osha-hipaa`}>
          <Button sx={buttonStyle}><img className={styles.image_icon} src={oshaHipaaIcon} alt="" />OSHA/HIPAA</Button>
        </Link>
        {/* WASTE MANAGEMENT */}
        <Button sx={buttonStyle} onClick={({ currentTarget }) => setActiveMenu(Menus.WasteManagement, currentTarget)}>
          <Stack direction="row" spacing={1} alignItems="center">
            <FontAwesomeIcon icon="earth-americas" className={styles.fa_icon} />
            <Typography variant="subtitle2" textTransform="capitalize">waste management</Typography>
            <FontAwesomeIcon icon="caret-down" className={styles.fa_icon} />
          </Stack>
        </Button>
        <Popper
          anchorEl={menuAnchorRef}
          open={menuOpen === Menus.WasteManagement}
          placement={'bottom-start'}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={() => clearMenus()}>
                  <MenuList id="waste-management-menu">
                    <MenuItem>
                      <img className={styles.image_icon} src={sharpsIcon} />
                      <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/medicalwaste`}><Typography variant="subtitle2" textTransform="capitalize">medical waste</Typography></Link>
                    </MenuItem>
                    <MenuItem>
                      <img className={styles.image_icon} src={unusedMeds1} />
                      <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/pharma-recovery`}><Typography variant="subtitle2" textTransform="capitalize">pharmaceutical waste</Typography></Link>
                    </MenuItem>
                    <MenuItem>
                      <img className={styles.image_icon} src={amalgam2} />
                      <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/amalgam-containers`}><Typography variant="subtitle2" textTransform="capitalize">amalgam</Typography></Link>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Link href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/upcoming`}>
          <Button sx={buttonStyle}><FontAwesomeIcon icon="truck" size="sm" className={styles.fa_icon} />Shipment Status</Button>
        </Link>
        <Link href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/auto-renewals`}>
          <Button sx={buttonStyle}><FontAwesomeIcon icon="arrows-rotate" className={styles.fa_icon} />Auto Renewals</Button>
        </Link>
        {/* TASKS */}
        <Button sx={buttonStyle} onClick={({ currentTarget }) => setActiveMenu(Menus.Tasks, currentTarget) }>
          <Stack direction="row" spacing={1} alignItems="center">
            <FontAwesomeIcon icon="bars-progress" size="sm" className={styles.fa_icon} />
            <Typography variant="subtitle2" textTransform="capitalize">tasks</Typography>
            <FontAwesomeIcon icon="caret-down" className={styles.fa_icon} />
          </Stack>
        </Button>
        <Popper
          anchorEl={menuAnchorRef}
          open={menuOpen === Menus.Tasks}
          placement={'bottom-start'}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={() => clearMenus()}>
                  <MenuList id="tasks-menu">
                    <MenuItem>
                      <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/covid19`}><Typography variant="subtitle2" textTransform="capitalize">infection control tasks</Typography></Link>
                    </MenuItem>
                    <MenuItem>
                      <Link variant='nav' href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/tasks`}><Typography variant="subtitle2" textTransform="capitalize">assigned tasks</Typography></Link>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        
        <Link href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/team-members`}>
          <Button sx={buttonStyle}><FontAwesomeIcon icon="user" size="sm" className={styles.fa_icon} />Team Members</Button>
        </Link>
        <Link href={`/corp/practice/${customerNumber}/training`}>
          <Button sx={buttonStyle}><FontAwesomeIcon icon="graduation-cap" className={styles.fa_icon} />Training</Button>
        </Link>
      </div>
      <Outlet />
    </div>
  );
};

export default PracticeDashboard;
