import { styled, TableCell, tableCellClasses, TableRow } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "'Open Sans', sans-serif",
  },
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "'Open Sans', sans-serif",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f9f9f9',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));