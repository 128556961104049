import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getTimezone } from '../services/userService';
import { getTimezoneLong } from '../helpers/date';
import { TeamMember } from '../types';

export enum UserAccessLevels {
  Owner = 'owner',
  Admin = 'admin',
  TeamMember = 'teamMember'
}

export interface User {
  access_level: UserAccessLevels;
  user_id: string | number;
  firstname?: string;
  lastname?: string;
  email?: string;
  name?: string;
  timezone?: string;
  account_active?: boolean;
  login_user_id?: number;
}

type UserState = User | null;

const initialState: UserState = null;

const sliceName = 'currentUser';

export const fetchUserTimezone = createAsyncThunk(`${sliceName}/fetchTimezone`, () => getTimezone().then((short) => getTimezoneLong(short)));

export const userSlice = createSlice({
  name: sliceName,
  initialState: initialState as UserState,
  reducers: {
    login: (_, action) => {
      return action.payload;
    },
    logout: () => {
      return initialState;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUserTimezone.fulfilled, (state, action) => {
      if (!state) {
        return;
      }
      state.timezone = action.payload;
    });
  }
});

export const { login, logout } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.currentUser;

export const selectUserId = createSelector(selectCurrentUser, (user) => user?.user_id);

export const selectUserTimezone = createSelector(selectCurrentUser, (u) => u?.timezone);

export const getFullNameOrEmail = (user: User | TeamMember | undefined) => user ? (user?.firstname && user?.lastname ? `${user?.firstname} ${user?.lastname}` : user?.email ?? 'No Name') : 'No Name';

export const selectIsAdminOrOwner = createSelector(selectCurrentUser, (user) => user?.access_level === UserAccessLevels.Admin || user?.access_level === UserAccessLevels.Owner);

export default userSlice.reducer;
